<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box :back="true" back-component-name="management.vatRate.index">
        <template v-slot:title>
          <span>{{ $t(id != null ? 'vatRate.edit_vatRate' : 'vatRate.create_vatRate') }}</span>
        </template>
        <template v-slot:preview>
          <div class="row d-flex" v-if="itemProxy">
            <div class="col-xl-6">
              <div class="d-flex items-end justify-content-start">
                <div>
                  <custom-multi-select
                      :title="$t('vatRate.rate')"
                      :model.sync="vat_id"
                      :options="vats"
                      name="vatRate_logo_name"
                      :not-list="true"
                      :is-inline="true"
                      :max="1"
                      :required="true"
                      :item-per-row="1"
                      input-width="auto"
                      input-max-width="200px"
                  ></custom-multi-select>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <custom-multi-select
                  :title="$t('vatRate.type') + ':'"
                  :model.sync="type_id"
                  :options="vat_types"
                  name="name"
                  :not-list="true"
                  :is-inline="true"
                  :max="1"
                  :item-per-row="1"
                  input-width="auto"
                  input-max-width="150px"
              ></custom-multi-select>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button-with-icon
                class="mr-3 danger-border-1px"
                @onClick="$router.go(-1)"
                :text="$t('general.cancel')"
                size="lg"
                :icon-name="null"
            ></button-with-icon>
            <button-with-icon
                @onClick="createOrUpdateVatRate"
                :text="$t(id != null ? 'general.save' : 'general.create')"
                size="lg"
                :icon-name="null"
                :disabled="!isValidToCreate"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR, GET_ITEMS,
  ITEMS,
  GET_ITEM_DETAIL_BY_ID,
  LOADING, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {LANGUAGE} from "@/core/services/store/system-options.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
  name: "VatRateCreateEdit",
  components: {
    ButtonWithIcon,
    TextInput,
    SelectInput,
    CustomMultiSelect,
    DashboardBox
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      vatRate: CURRENT_ITEM,
      language: LANGUAGE,
      ordervatRateTypeItems: ITEMS,
    }),
    isValidToCreate() {
      if (this.vatRate == null || this.vat_id == null|| this.type_id == null) return false;

      return true;
    },
    itemProxy: {
      get() {
        if (this.id != null && this.vatRate) {
          this.type_id = this.vatRate.type_id;
          this.vat_id = this.vatRate.vat_id;
        }

        return this.vatRate;
      },
      set(value) {
        this.vatRate(value);
      }
    }
  },
  data() {
    return {
      id: null,
      languages: [],
      vat_types: {},
      vats: {},
      vat_rates: {},
      type_id: this.id ? this.itemProxy.type_id : null,
      vat_id: this.id ? this.itemProxy.vat_id : null,
      is_default: this.id ? this.itemProxy.is_default : false,
      tempItem: {
      }
    };
  },
  methods: {
    ...mapMutations({
      setVatRate: SET_CURRENT_ITEM,
    }),
    getVats() {
      let payload = {
        url: 'api/vats',
        returnType: "stateless"
      }
      this.$store.dispatch(GET_ITEMS, payload).then(response => {
        if (response.status) {
          this.vats = this.convertArrayToObjectByKey(response.data, 'id', 'rate');
        }
      })
    },
    getVatTypes () {
      let payload = {
        url: 'api/vat-types',
        returnType: "stateless"
      }
      this.$store.dispatch(GET_ITEMS, payload).then(response => {
        if (response.status) {
          this.vat_types = response.data;
        }
      })
    },
    createOrUpdateVatRate() {
      let payload = {
        url: this.id == null ? 'api/vat-rates' : 'api/vat-rates/' + this.id,
        id: this.id,
        contents: {
          type_id: this.type_id,
          vat_id: this.vat_id,
        },
        redirectRouteName: 'management.vatRate.index',
        successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
      }
      this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);
    },
  },
  mounted() {
    this.id = this.$route.params.id;

    if (this.id != null) {
      let payload = {
        url: 'api/vat-rates/' + this.id,
        id: this.id,
        redirectRouteOnNotFound: 'management.vatRate.index'
      }

      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload)
    }

    this.getVats();
    this.getVatTypes();

    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("vatRate.vatRates"), route: "management.vatRate.index"},
      {title: this.$t(this.id != null ? "vatRate.edit_vatRate" : "vatRate.create_vatRate")},
    ]);

    if (!this.id) {
      let item = {
        vat_id: 1,
        type_id: 1
      }
      this.setVatRate(item);
    }
  }
}
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
